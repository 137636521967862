import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';

export const Container = styled.footer`
  padding: 3.5rem;
  background-color: ${(props: ThemeProps) => props.theme.backgroundColor};
  @media (max-width: 992px) {
    padding: 3.5rem 1.875rem;
  }
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const Wrapper = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 5rem;
  border-right: 1px solid #dae7db;
  @media (max-width: 856px) {
    padding-right: 3rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    border-bottom: 1px solid #dae7db;
    border-right: none;
    padding: 0 0 0.75rem 0;
  }
`;

export const Navigation = styled.div``;

export const NavLinks = styled.div`
  margin-top: 2.75rem;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  & a {
    width: fit-content;
    padding: 0.75rem 0;
    &:hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 768px) {
    margin-top: 1.25rem;
  }
`;

export const LogoLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  margin-left: -0.875rem;
  margin-top: -0.5rem;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Copyright = styled.span`
  font-weight: 400;
  font-size: 0.8125rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const CopyrightBottom = styled(Copyright)`
  display: none;
  text-align: center;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Right = styled(Left)`
  width: 70%;
  padding-right: 0;
  padding-left: 5rem;
  border: none;
  @media (max-width: 856px) {
    padding-left: 3rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin: 2rem 0;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const CategoriesContainer = styled.div`
  margin-bottom: 3.5rem;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const Categories = styled.ul`
  display: grid;
  font-size: 0.875rem;
  font-weight: 400;
  grid-template-columns: repeat(3, minmax(13rem, 14rem));
  row-gap: 1rem;
  column-gap: 1rem;
  margin: 0;
  padding: 0;
  list-style: none;
  @media (max-width: 1140px) {
    grid-template-columns: repeat(2, minmax(13rem, 100%));
  }
  @media (max-width: 498px) {
    grid-template-columns: repeat(1, 100%);
  }
  & li {
    & a:hover {
      display: inline-block;
      transform: scale(1.1);
    }
  }
`;

export const Social = styled.div`
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  & > div:last-child {
    margin-left: 1.625rem;
    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }
}`;

export const SocialLinks = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  & li {
    margin-right: 1.625rem;
    & a {
      display: flex;
      &:hover {
        transform: scale(1.1);
      }
      & svg {
        height: 1.125rem;
        margin-right: 0.625rem;
      }
    }
  }
`;
