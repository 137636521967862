import Link from 'next/link';
import {
  Container,
  Wrapper,
  Left,
  Navigation,
  NavLinks,
  LogoLinkWrapper,
  Copyright,
  CopyrightBottom,
  Right,
  CategoriesContainer,
  Categories,
  Title,
  Social,
  SocialLinks,
} from './Footer.styles';
import { categoriesArray } from '@/blocks/CategoriesBlock/mockData';
import { Twitter, Instagram } from '@/icons';
import { useRouter } from 'next/router';
import isBlog from '@/utils/isBlog';

const Footer = () => {
  const router = useRouter();
  return (
    <Container>
      <Wrapper>
        <Left>
          <Navigation>
            <Link href="/" passHref>
              <LogoLinkWrapper>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src="/logo.png"
                  alt="JobsHash Logo"
                  width="152px"
                  height="32px"
                  loading="lazy"
                />
              </LogoLinkWrapper>
            </Link>
            <NavLinks>
              {isBlog(router.pathname) ? (
                <Link href="/" passHref>
                  Jobs
                </Link>
              ) : // <Link href="/blog" passHref>
              //   Blog
              // </Link>
              null}
            </NavLinks>
          </Navigation>
          <Copyright>&#169; 2022, JobsHash. All rights reserved.</Copyright>
        </Left>
        <Right>
          <CategoriesContainer>
            <Title>Job Categories</Title>
            <Categories>
              {categoriesArray?.length &&
                categoriesArray.map((el) => (
                  <li key={el.title}>
                    <Link href={`/${el.value}`} passHref>
                      {el.title}
                    </Link>
                  </li>
                ))}
            </Categories>
          </CategoriesContainer>
          <Social>
            <div>
              <Title>Follow Us</Title>
              <SocialLinks>
                <li>
                  <a
                    href="https://www.instagram.com/jobshash/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram />
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/jobshash/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter />
                    Twitter
                  </a>
                </li>
              </SocialLinks>
            </div>
            <div>
              <Title>Contact Us</Title>
              <SocialLinks>
                <li>
                  <a href="mailto:hi@jobshash.com">hi@jobshash.com</a>
                </li>
              </SocialLinks>
            </div>
          </Social>
        </Right>
      </Wrapper>
      <CopyrightBottom>
        &#169; 2022, JobsHash. All rights reserved.
      </CopyrightBottom>
    </Container>
  );
};

export default Footer;
